<template>
  <div>
    <!-- <v-row>
      <v-col
        cols="12"
      > -->

    <v-card
      v-if="paymentData"
    >
      <!-- <v-fab-transition>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  color="secondary"
                  dark
                  absolute
                  small
                  top
                  right
                  fab
                  class="mb-4 me-3"
                  v-on="on"
                  @click="goBack"
                >
                  <v-icon>
                    {{ icons.mdiTableArrowLeft }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ t("tooltip.back_table") }}</span>
            </v-tooltip>
          </v-fab-transition> -->

      <v-card-text class="pt-6 pb-0 px-8">
        <v-row class="d-flex justify-space-between">
          <v-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-center">
              <v-avatar
                :color="paymentData.facility.logo ? '' : 'primary'"
                :class="paymentData.facility.logo ? '' : 'v-avatar-light-bg primary--text'"
                size="64"
              >
                <v-img
                  v-if="paymentData.facility.logo"
                  :src="paymentData.facility.logo"
                ></v-img>
                <span
                  v-else
                  class="font-weight-regular"
                >{{ avatarText(paymentData.facility.facility_name) }}</span>
              </v-avatar>

              <h2 class="font-weight-medium ml-2">
                {{ paymentData.facility.facility_name }}
              </h2>
            </div>
          </v-col>

          <v-col
            class="d-flex align-center"
            cols="12"
            md="4"
          >
            <div class="d-flex align-center">
              <h2 class="font-weight-medium">
                {{ t('transactions.payment_receipt') }}
              </h2>
            </div>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-space-between">
          <v-col
            class="d-flex justify-space-between"
            cols="12"
            md="4"
          >
            <div>
              <table>
                <tr>
                  <td>
                    {{ paymentData.facility.facility_address }}
                  </td>
                </tr>
                <tr>
                  <td>
                    {{ paymentData.facility.phone }}
                  </td>
                </tr>
              </table>
            </div>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <div>
              <table>
                <tr>
                  <td class="pe-6">
                    {{ t('transactions.date') }}:
                  </td>
                  <td>
                    {{ formatStdDate(paymentData.payment_datetime_str, 'datetime-custom', configObjFacility.is12Hour, $i18n.locale) }}
                  </td>
                </tr>

                <tr>
                  <td class="pe-6">
                    ID:
                  </td>
                  <td>
                    #{{ paymentData.id }}
                  </td>
                </tr>
                <tr>
                  <td class="pe-6">
                    {{ t('status.status') }}:
                  </td>
                  <td>
                    {{ paymentData.status_str }}
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text class="pt-6 pb-0 px-8">
        <div class="d-flex justify-end mb-2">
          <v-btn
            v-if="hasPermission(98)"
            class="left-align"
            color="secondary"
            x-small
            @click="generateReport()"
          >
            <v-icon
              class="me-2"
              left
            >
              {{ icons.mdiPrinter }}
            </v-icon>
            <span>{{ t('tooltip.print') }}</span>
          </v-btn>
        </div>
        <v-simple-table
          v-if="paymentData.products.length"
          class="purchased-items-table-bordered"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th>
                  {{ t('products.name').toUpperCase() }}
                </th>
                <!-- <th class="text-end">
                      {{ t('products.qty').toUpperCase() }}
                    </th> -->
                <th class="text-end">
                  TOTAL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in paymentData.products"
                :key="item.id"
              >
                <td>
                  {{ item.name }}
                </td>
                <!-- <td class="text-end">
                      {{ item.qty }}
                    </td> -->
                <td class="text-end">
                  {{ formatCurrency(item.subtotal) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="ma-0 d-flex">
          <span
            v-if="paymentData.note"
            class="mt-4"
          >
            <span class="font-weight-semibold">{{ t('transactions.note') }}: </span>
            <span>{{ t('transactions.payment_of_booking') }} #{{ paymentData.invoice_reservation_id }}</span>
          </span>
        </div>

        <div class="ma-0 d-flex">
          <span
            v-if="paymentData.payment_medium"
            class="mt-2"
          >
            <span class="font-weight-semibold">{{ t('reservation_detail.origin') }}: </span>
            <span>{{ paymentData.payment_medium }}</span>
          </span>
        </div>

        <div class="ma-0 d-flex">
          <span
            class="mt-2"
          >
            <span class="font-weight-semibold">{{ t('transactions.payment_agent') }}: </span>
            <span>{{ paymentData.user ? paymentData.user : (paymentData.user_app && !paymentData.user) ? 'Online' : '' }}</span>
          </span>
        </div>
      </v-card-text>

      <v-card-text class="py-6 px-8">
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="3"
          >
            <!-- <span class="font-weight-semibold test-body-1">
                  {{ t('transactions.payment_agent') }}:
                </span>
                <span class="font-weight-light test-body-1">
                  {{ paymentData.user? paymentData.user : paymentData.user_app ? paymentData.user_app : '' }}
                </span> -->
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <div>
              <table
                v-if="paymentData.status !== 'D'"
                class="w-100"
              >
                <tr>
                  <td class="pe-16 font-weight-light">
                    Subtotal:
                  </td>
                  <th
                    class="font-weight-light"
                    :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                  >
                    {{ formatCurrency(paymentData.invoice_subtotal) }}
                  </th>
                </tr>

                <tr>
                  <td class="pe-16 font-weight-light">
                    {{ t('video_details.tax') }}:
                  </td>
                  <th
                    class="font-weight-light"
                    :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                  >
                    {{ formatCurrency(paymentData.invoice_tax) }}
                  </th>
                </tr>

                <tr>
                  <td class="pe-16 font-weight-light">
                    {{ t('video_details.fee') }}:
                  </td>
                  <th
                    class="font-weight-light"
                    :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                  >
                    {{ formatCurrency(paymentData.invoice_fanaty_fee) }}
                  </th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16 font-weight-light">
                    Total:
                  </td>
                  <th
                    class="font-weight-semibold"
                    :class="$vuetify.rtl ? 'text-left' : 'text-right'"
                  >
                    {{ formatCurrency(paymentData.invoice_amount) }}
                  </th>
                </tr>
                <tr
                  v-if="paymentData.status === 'R'"
                >
                  <td class="pe-16 success--text font-weight-light">
                    {{ t('status.refunded') }}:
                  </td>
                </tr>
              </table>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- </v-col>
    </v-row> -->

    <vue-html2pdf
      v-if="paymentData && paymentData.id && showPDF"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :enable-links="true"
      :preview-modal="true"
      :paginate-elements-by-height="1100"
      :filename="paymentData? `Receipt #${paymentData.id} ${formatStdDate(paymentData.payment_datetime_str, 'datetime-custom', configObjFacility.is12Hour, $i18n.locale)}` : 'Receipt'"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      :html-to-pdf-options="{
        html2canvas: {
          scrollX: 0,
          scrollY: 0,
          imageTimeout: 10000,
          useCORS: true,
          allowTaint: true,
        },
      }"
      @beforeDownload="beforeDownload()"
      @hasDownloaded="hasDownloaded()"
    >
      <section
        slot="pdf-content"
        style="background-color: #ffffff; width: 100%; height: 100%"
      >
        <pos-payment-history-report
          v-if="true"
          :data-params="computedPaymentData"
          :has-transaction-video="hasTransactionVideo"
        />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-body-style */
import { ref, computed, watch, onBeforeUnmount, onMounted } from '@vue/composition-api'
import { getInvoicePayment } from '@api'
import { mdiSendOutline, mdiCurrencyUsd, mdiTableArrowLeft, mdiPrinter } from '@mdi/js'
import themeConfig from '@themeConfig'
import { useRouter, formatCurrency, formatStdDate } from '@core/utils'
import { useUtils } from '@core/libs/i18n'
import { info, success } from '@core/utils/toasted'
import usePermissions from '@core/utils/usePermissions'
import { avatarText } from '@core/utils/filter'
import useSelectOptions from '@/@core/utils/useSelectOptions'

import VueHtml2pdf from 'vue-html2pdf'
import PosPaymentHistoryReport from '@/templates/reports/PosPaymentHistoryReport.vue'

// composition function
import usePaymentList from '../payment-list/usePaymentList'

export default {
  components: {
    PosPaymentHistoryReport,
    VueHtml2pdf,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    option: {
      type: Number,
      required: false,
      default: 2,
    },
    dataId: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { router } = useRouter()
    const { t } = useUtils()
    const { hasPermission } = usePermissions()
    const { configObjFacility } = useSelectOptions()
    const { resolveClientAvatarVariant } = usePaymentList()

    // const dataId = ref(null)
    const computedIsOpen = computed({
      get: () => props.isOpen,
      set: val => emit('update:is-open', val),
    })
    const paymentData = ref(null)
    const initData = ref({
      id: null,
      amount: null,
      description: null,
      payment_date: null,
      status: null,
      user: {
        username: null,
        fullname: null,
      },
      user_app: {
        username: null,
        fullname: null,
      },
      group: {
        group_name: null,
        logo: null,
        email: null,
        phone: null,
      },
      payment_method: {
        id: null,
        owner_name: null,
        number: null,
        brand: null,
        billing_address: null,
        billing_email: null,
        billing_zip_code: null,
        group_id: null,
        billing_phone: null,
      },
      transactions: [],
      products: [],
      facility: {
        facility_name: null,
        facility_address: null,
        city_name: null,
        state_code: null,
        state_name: null,
        logo: null,
        email: null,
        phone: null,
      },
    })
    const computedPaymentData = computed(() => {
      if (paymentData.value) return paymentData.value

      return initData.value
    })
    const videoData = ref(null)
    const transactionData = ref(null)
    const hasTransactionVideo = ref(false)
    const pageData = ref()
    const filterData = ref()
    const html2Pdf = ref(null)
    const showPDF = ref(false)
    const dateNow = ref(new Date().toISOString().substring(0, 10))

    const getSubtotals = trans => {
      const subtotal = trans.reduce((acc, cur) => acc + cur.subtotal, 0)

      return formatCurrency(subtotal)
    }

    const getDiscounts = trans => {
      const discount = trans.reduce((acc, cur) => acc + cur.discount, 0)

      return discount !== 0 ? `(${formatCurrency(discount)})` : '($0.00)'
    }

    const getComission = trans => {
      const comission = trans.reduce((acc, cur) => acc + cur.comission, 0)

      return comission !== 0 ? `(${formatCurrency(comission)})` : '($0.00)'
    }

    const getTax = trans => {
      const tax = trans.reduce((acc, cur) => acc + cur.tax, 0)

      return tax !== 0 ? `${formatCurrency(tax)}` : '$0.00'
    }

    const getRefunded = trans => {
      const refunded = trans.filter(e => e.refunded === 'Y').reduce((acc, cur) => acc + cur.subtotal, 0)

      return refunded !== 0 ? `${formatCurrency(refunded)}` : '($0.00)'
    }

    const goBack = () => {
      if (videoData.value) {
        router.push({
          name: 'views-video-preview',
          params: {
            id: videoData.value.id,
            dataParams: videoData.value,
            dataTransaction: transactionData.value,
            option: 2,
            pageParams: pageData.value,
            filterParams: filterData.value,
          },
        })
      } else {
        router.push({
          name: 'views-pos-payment-list',
          params: {
            pageParams: pageData.value,
            filterParams: filterData.value,
          },
        })
      }
    }

    const generateReport = () => {
      showPDF.value = true
      setTimeout(() => {
        html2Pdf.value.generatePdf()
      }, 200)
    }

    const beforeDownload = () => {
      info('Generating PDF!')
    }

    const hasDownloaded = () => {
      success('PDF download successful!')
    }

    const initForm = async () => {
      // dataId.value = route.value.params.id
      if (props.dataId) {
        const resp = await getInvoicePayment(props.dataId)
        if (resp.ok) {
          paymentData.value = resp.data
        }

        // else goBack()
      }

      // else goBack()

      if (paymentData.value) {
        // videoData.value = route.value.params.dataVideo
        // transactionData.value = route.value.params.dataTransaction
        // pageData.value = route.value.params.pageParams
        // filterData.value = route.value.params.filterParams
      }
    }

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        await initForm()
      } else {
        paymentData.value = null
      }
    })

    onBeforeUnmount(() => {
      showPDF.value = false
    })

    onMounted(async () => {
      await initForm()
    })

    return {
      // data
      paymentData,
      initData,
      computedPaymentData,
      videoData,
      hasTransactionVideo,
      pageData,
      filterData,
      dateNow,
      html2Pdf,
      showPDF,
      configObjFacility,
      computedIsOpen,

      // methods
      formatCurrency,
      getSubtotals,
      getDiscounts,
      getComission,
      getTax,
      getRefunded,
      goBack,
      resolveClientAvatarVariant,
      generateReport,
      beforeDownload,
      hasDownloaded,
      hasPermission,
      avatarText,
      formatStdDate,

      // i18n
      t,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
        mdiTableArrowLeft,
        mdiPrinter,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }

  .d-contents {
    display: contents;
  }
}
</style>
